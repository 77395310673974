export const colorsV = [
	{ key:'RAL9003',	ral:'9003',	name:"Bianco RAL9003",			code:"#ecece7", colorCheck:"black", price:0, default:true },
	{ key:'RAL9005',	ral:'9005',	name:"Nero RAL9005",			code:"#0e0e10", colorCheck:"white", price:6500 },
	{ key:'RAL9006',	ral:'9006',	name:"Grigio alluminio RAL9006",	code:"#a1a1a0", colorCheck:"black", price:6500 },
	{ key:'RAL7016',	ral:'7016',	name:"Grigio antracite RAL7016",	code:"#383e42", colorCheck:"white", price:6500 },
	{ key:'RAL1003',	ral:'1003',	name:"Giallo segnale RAL1003",		code:"#f9a800", colorCheck:"black", price:6500 },
]

export const colorsPC = [
	{ key:'RAL5010',	ral:'5010',	name:"Blu segnale RAL5010",	code:"#004f7c", colorCheck:"white", default:true },
	{ key:'RAL7045',	ral:'7045',	name:"Grigio chiaro RAL7045",	code:"#8d9295", colorCheck:"black"},
]
