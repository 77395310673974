import React from 'react';

function pathCalc(props, node) {
	let depth = props.depth ? props.depth : 0
	if(props.path && props.path.length>depth && node.tag===props.path[depth].key)
		return props.path
}

function NodeSub(props) {
	let depthSub = parseInt(props.depth)+1
	let subs = props.tree.map((node)=>{
		if(node.parent===props.tag)
			return <NodeSub key={node.tag} tag={node.tag} label={node.name} tree={props.tree} nav={props.nav} depth={depthSub} path={pathCalc(props, node, props.depth)} />
		else
			return null
	})
	let clName = props.path ? 'is-active' : ''

	return (
<li>
		<a href="#" className={clName} onClick={e => props.nav(e, 'cat/'+props.tag)}>{props.label}</a>
		{subs &&
			<ul>{subs}</ul>
		}
</li>
	)
}

function NodeRoot(props) {
	let subs = props.tree.map((node)=>{
		if(node.parent===props.tag)
			return <NodeSub key={node.tag} tag={node.tag} label={node.name} tree={props.tree} nav={props.nav} depth="2" path={pathCalc(props, node, props.depth)} />
		else
			return null
	})

	return (
<>
		<p className="menu-label clickable" onClick={e => props.nav(e, 'cat/'+props.tag)}>{props.label}</p>
		{subs &&
			<ul className="menu-list">{subs}</ul>
		}
</>
	)
}

export default function Sidebar(props) {
	let menu = props.tree.map((node)=>{
		if(!node.parent)
			return <NodeRoot key={node.tag} tag={node.tag} label={node.name} tree={props.tree} nav={props.nav} path={pathCalc(props, node, 0)} depth="1" />
		else
			return null
	})

	return (
<div className="column is-narrow sidebar">	
	<aside className="menu category-menu">{menu}</aside>
</div>
	)
}
