import React, { useEffect } from "react"
import { dspMoney } from '../lib/vconv'

export default function Cart(props) {
	function updDiscount(e) {
		props.mgm.discount(e.target.value)
	}
	
	useEffect(() => {
		if(props.discount===null)
			props.mgm.discount( props.customer.discount ? props.customer.discount/100 : 0 )
	})

	let totalBase = props.items.reduce((acc, cur) => {
		acc += (cur.qty*cur.price)
		if(cur.kit) {
			let variant = props.vars.find(variant => variant.id===cur.id)
			acc += (cur.qty*variant.kit_price/100)
		}
		return acc
	}, 0)
	const discount = -totalBase * props.discount / 100
	const shipPrice = props.ship ? props.ship : 90
	const shipQty = props.items.reduce((acc, cur) => {
		if(cur.id)
			acc+=cur.qty
		return acc
	}, 0)
	const ship = shipPrice * shipQty
	const total = dspMoney(totalBase + ship + discount)

	return (
<>
	<h1>Conferma carrello</h1>
	<div className="table-container"><table className="table">
		<thead>
			<tr>
				<th>Descrizione</th>
				<th></th>
				<th>Totale</th>
			</tr>
		</thead>
		<tfoot className="cart-footer">
		<tr>
			<td colSpan="2" className="">Importo articoli</td>
			<td className="has-text-right">{dspMoney(totalBase)}</td>
		</tr>
		<tr>
			<td className="has-text-right">Sconto</td>
			<td>
				<div className="field has-addons">
					<p className="control">
						<input type="number" className="input" step="0.01" value={props.discount ? props.discount : 0} onChange={updDiscount} />
					</p>
					<p className="control">
						<span className="button is-static">%</span>
					</p>
				</div>
			</td>
			<td className="has-text-right">{dspMoney(discount)}</td>
		</tr>
		<tr>
			<td colSpan="2" className="">Spese di spedizione</td>
			<td className="has-text-right">{dspMoney(ship)}</td>
		</tr>
		
		<tr>
			<td colSpan="2" className="">Totale complessivo</td>
			<td className="has-text-right">{total}</td>
		</tr></tfoot>
	</table></div>
	<div className="buttons cart-bottom">
		<button className="button is-info" onClick={e => props.nav(e, 'quote-done')}>
			<span className="icon is-small"><i className="fas fa-arrow-right"></i></span> <span>Conferma preventivo</span>
		</button>
	</div>
</>
	)
}
