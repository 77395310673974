import React from 'react';

function BarBtn(props) {
	let clName="navbar-item clickable"
	if(props.classExtra)
		clName += ' '+props.classExtra

	return (
<div className={clName} onClick={props.onClick}>
	{props.label}&nbsp;<i className={props.icon}></i>
</div>
	)
}

function CartBtn(props) {
	if(props.cart===false)
		return null
	else {
		const label = '('+props.cart.reduce((acc, cur) => acc+=cur.qty, 0)+')'
		return <BarBtn label={label} icon="fas fa-shopping-cart" classExtra={props.classExtra} onClick={e => props.nav(e, 'cart')} />
	}
}

export default function Navbar(props) {
	function execLogout(e) {
		e.preventDefault()
		e.stopPropagation()

		props.updAuth(null)
	}

	let menuItems
	if(props.isAuth)
		menuItems = (
<>
	<BarBtn label="Home portale" icon="fas fa-home" onClick={execLogout} />
	<BarBtn label="Archivio" icon="fas fa-archive" onClick={e => props.nav(e, 'quotes')} />
</>
		)
	/*
		menuItems = (
<>
	<BarBtn label="Logout" icon="fas fa-sign-out-alt" onClick={execLogout} />
	{ props.jwtAdmin && <BarBtn label="Statistiche" icon="fas fa-calculator" onClick={e => props.nav(e, 'stats')} /> }
	<BarBtn label="Preventivi" icon="fas fa-file-invoice" onClick={e => props.nav(e, 'quotes')} />
	<BarBtn label="Clienti" icon="fas fa-address-book" onClick={e => props.nav(e, 'addrbook')} />
</>
		)
		*/

	const classMenu = "navbar-menu" + ( props.burger ? ' is-active' : '' )
	const classBurger = "navbar-burger burger" + ( props.burger ? ' is-active' : '' )

	return (
<nav className="navbar" role="navigation" aria-label="main navigation">
	<div className="container">
		<div className="navbar-brand">
			<a className="navbar-item" href="#" onClick={e => props.nav(e, null)}>
				<img src="/img/logo.jpg" alt="AHT"/>
			</a>
			<a role="button" className={classBurger} aria-label="menu" aria-expanded="false" onClick={() => props.setBurger(!props.burger)}>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
			<CartBtn cart={props.cart} nav={props.nav} classExtra="cart-brand" />
		</div>
		<div className={classMenu}>
			<div className="navbar-end">
				{menuItems}
				<CartBtn cart={props.cart} nav={props.nav} />
			</div>
		</div>
	</div>
</nav>
	)
}
