import React from "react"

function Field(props) {
	let value = props.value ? props.value : ''

	return (
<div className="field is-horizontal">
	<div className="field-label">
		<label className="label">{props.label}</label>
	</div>
	<div className="field-body">
		<div className="field is-expanded">
			<div className="control">
				<input className="input" type="text" disabled={props.ro} placeholder={props.label} value={value} name={props.name} onChange={props.onChange} />
			</div>
		</div>
	</div>
</div>
	)
}

function FieldDiscount(props) {
	let value = props.value ? props.value/100 : ''

	function onChange(e) {
		props.onChange(e, e.target.value*100)
	}

	return (
<div className="field is-horizontal">
	<div className="field-label">
		<label className="label">{props.label}</label>
	</div>
	<div className="field-body">
		<div className="field is-expanded">
			<div className="control">
				<input className="input" type="number" step="0.01" disabled={props.ro} placeholder={props.label} value={value} name={props.name} onChange={onChange} />
			</div>
		</div>
	</div>
</div>
	)
}

export default function FormCustomer(props) {
	function onChange(e, valOverride) {
		let tmp = {...props.data}
		if(valOverride!==undefined)
			tmp[e.target.name] = valOverride
		else
			tmp[e.target.name] = e.target.value
		props.setData(tmp)
	}
	const form = props.data

	return (
<>
	<Field label="Denominazione sociale" name="name" ro={props.ro} value={form.name} onChange={onChange} />
	<Field label="Partita IVA" name="vat" ro={props.ro} value={form.vat} onChange={onChange} />
	<Field label="Codice fiscale" name="tax_code" ro={props.ro} value={form.tax_code} onChange={onChange} />
	<Field label="Identificativo sede" name="cdc" ro={props.ro} value={form.cdc} onChange={onChange} />
	<Field label="Indirizzo" name="address" ro={props.ro} value={form.address} onChange={onChange} />
	<Field label="Città" name="city" ro={props.ro} value={form.city} onChange={onChange} />
	<Field label="Provincia" name="province" ro={props.ro} value={form.province} onChange={onChange} />
	<Field label="CAP" name="zip" ro={props.ro} value={form.zip} onChange={onChange} />
	<Field label="Nazione" name="country" ro={props.ro} value={form.country} onChange={onChange} />
	<Field label="E-mail" name="email" ro={props.ro} value={form.email} onChange={onChange} />
	<Field label="Telefono" name="phone" ro={props.ro} value={form.phone} onChange={onChange} />
	<Field label="Cellulare" name="mobile" ro={props.ro} value={form.mobile} onChange={onChange} />
	<Field label="SDI" name="sdi" ro={props.ro} value={form.sdi} onChange={onChange} />
	<Field label="PEC" name="pec" ro={props.ro} value={form.pec} onChange={onChange} />
	<FieldDiscount label="Default sconto" name="discount" ro={props.ro} value={form.discount} onChange={onChange} />
</>
	)
}
