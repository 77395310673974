import React from "react"
import { dspMoney } from '../lib/vconv'
import Colorpicker, { defaultColorV, defaultColorPC } from '../Colorpicker'

function RowQty(props) {
	function updQty(e) {
		props.chQty(props.id, e.target.value)
	}
	function updQtyTmp(e) {
		props.chQty(props.id, e.target.value, true)
	}
	let multi = parseInt(props.multi)
	let remClass,remIcon
	if(props.qty<=multi) {
		remIcon = <i className="fas fa-trash"></i>
		remClass = 'button is-danger'
	}
	else {
		remIcon = <>
			<i className="fas fa-minus"></i>
			{multi>1 ? multi : ''}
		</>
		remClass = 'button is-info'
	}

	return (
<div className="field has-addons">
	<p className="control">
		<span className={remClass} onClick={() => props.chQty(props.id, props.qty-multi)}>
			{remIcon}
		</span>
	</p>
	<p className="control">
		<input type="number" className="input" min="0" value={props.qty} onChange={updQtyTmp} onBlur={updQty} />
	</p>
	<p className="control">
		<span className="button is-info" onClick={() => props.chQty(props.id, props.qty+multi)}>
			<i className="fas fa-plus"></i>{multi>1 ? multi : ''}
		</span>
	</p>
</div>
	)
}

function RowPrice(props) {
	function updField(e) {
		props.onEdit(null, e.target.value)
	}

	if(props.onEdit)
		return (
<div className="field has-addons">
	<p className="control">
		<input type="number" className="input" step="0.01" value={props.value} onChange={updField} />
	</p>
	<p className="control">
		<span className="button is-static">€</span>
	</p>
</div>
		)
	else
		return dspMoney(props.value)
}

function RowLabel({ onEdit, value, colorV, setColorV, colorPC, setColorPC }) {
	function updField(e) {
		onEdit(e.target.value, null)
	}

	if(onEdit)
		return <textarea className="textarea" placeholder="Descrizione" value={value} onChange={updField} />
	else
		return (
			<>
				{value}
				<Colorpicker colorV={colorV} setColorV={setColorV} colorPC={colorPC} setColorPC={setColorPC} />
			</>
		)
}

function Row(props) {
	const img = props.def.img ? props.def.img : "https://preventivi.ahtitalia.it/img/default_260x260.png"
	let onEdit
	if(!props.def.id)
		onEdit = (label, price) => {
			props.mgm.edit(label ? label:props.def.label, price ? price:props.def.price, props.def.key)
		}

	function chKit(e) {
		props.mgm.chKit(props.def.key, e.target.checked)
	}
	function chColor(colorV, colorPC) {
		props.mgm.chColor(props.def.key, colorV, colorPC)
	}

	let kitSwitch,kit
	if(props.variant) {
		const kitId = 'kit-'+props.def.key
		kitSwitch = (
<div className="field">
	<input id={kitId} type="checkbox" name="kit" className="switch is-rounded" defaultChecked="checked" onChange={chKit} />
	<label htmlFor={kitId}><span className="display-none">Kit</span></label>
</div>
		)
	
		if(props.def.kit) {
			const kit_descr = { __html: props.variant.kit_descr.replace(/\n/g, "<br />") }
			const kit_price = props.variant.kit_price/100
			kit = (
<tr className="kit-row">
	<td></td>
	<td dangerouslySetInnerHTML={kit_descr}></td>
	<td className="has-text-right">{dspMoney(kit_price)}</td>
	<td></td><td></td>
	<td className="has-text-right">{dspMoney(kit_price*props.def.qty)}</td>
</tr>
			)
		}
	}

	let multi = props.variant ? props.variant.multiplier : 1

	return (
<>	
	<tr>
		<td>
			<img className="cart-img" src={img} alt="immagine prodotto" />
		</td>
		<td>
			<RowLabel value={props.def.label} onEdit={onEdit} colorV={props.def.colorV} setColorV={(color) => chColor(color, null)} colorPC={props.def.colorPC} setColorPC={(c) => chColor(null, c)} />
		</td>
		<td className="has-text-right">
			<RowPrice value={props.def.price} onEdit={onEdit} />
		</td>
		<td>{props?.variant?.kit_price>0 && kitSwitch}</td>
		<td>
			<RowQty id={props.def.key} qty={props.def.qty} chQty={props.mgm.chQty} multi={multi} />
		</td>
		<td className="has-text-right">
			<RowPrice value={props.def.price*props.def.qty} />
		</td>
	</tr>
	{kit}
</>
	)
}

export default function Cart(props) {
	/*
	function updDiscount(e) {
		props.mgm.discount(e.target.value)
	}
	*/
	function updShip(e) {
		props.mgm.ship(e.target.value)
	}

	let rows = props.items.map(item => <Row key={item.key} def={item} mgm={props.mgm} variant={props.vars.find(variant => variant.id===item.id)} />)
	if(!rows.length)
		rows = ( <tr><td colSpan="6" className="has-text-centered">Non ci sono articoli nel carrello</td></tr> )
	let total = props.items.reduce((acc, cur) => {
		acc += (cur.qty*cur.price)
		if(cur.kit) {
			let variant = props.vars.find(variant => variant.id===cur.id)
			acc += (cur.qty*variant.kit_price/100)
		}
		return acc
	}, 0)
	const discount = -total * props.discount / 100
	const shipPrice = props.ship ? props.ship : 90
	const shipQty = props.items.reduce((acc, cur) => {
		if(cur.id)
			acc+=cur.qty
		return acc
	}, 0)
	const ship = shipPrice * shipQty
	total = dspMoney(total + ship + discount)

	/*
				<div className="field has-addons">
				</div>
					<p className="control">
						<input type="number" className="input" step="0.01" value={props.discount} onChange={updDiscount} />
					</p>
					<p className="control">
						<span className="button is-static">%</span>
					</p>
	*/
	return (
<>
	<h1>Carrello</h1>
	<div className="table-container"><table className="table">
		<thead>
			<tr>
				<th></th>
				<th>Articolo</th>
				<th>Prezzo</th>
				<th>Kit</th>
				<th>Quantità</th>
				<th>Totale</th>
			</tr>
		</thead>
		<tbody>{rows}</tbody>
		<tfoot className="cart-footer">
		<tr>
			<td colSpan="4" className="has-text-right">Sconto</td>
			<td>
				{props.discount ? props.discount : 0} %
			</td>
			<td className="has-text-right">{dspMoney(discount)}</td>
		</tr>
		<tr>
			<td colSpan="2" className="has-text-right">Spese di spedizione</td>
			<td>
				<select value={props.ship} onChange={updShip}>
					<option value="90">90 &euro;</option>
					<option value="100">100 &euro;</option>
					<option value="110">110 &euro;</option>
					<option value="120">120 &euro;</option>
				</select>
			</td>
			<td></td>
			<td>{shipQty}</td>
			<td className="has-text-right">{dspMoney(ship)}</td>
		</tr>
		
		<tr>
			<td colSpan="5" className="has-text-right">Totale complessivo</td>
			<td className="has-text-right">{total}</td>
		</tr></tfoot>
	</table></div>
	<div className="buttons cart-bottom">
	<button className="button is-success" onClick={() => props.mgm.edit('', '')}>
		<span className="icon is-small"><i className="fas fa-plus"></i></span> <span>Aggiungi riga personalizzata</span>
	</button>
	<button className="button is-info" onClick={e => props.nav(e, 'sel-customer')}>
		<span className="icon is-small"><i className="fas fa-arrow-right"></i></span> <span>Prosegui</span>
	</button>
	</div>
</>
	)
}
