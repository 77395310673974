import React, { useState, useEffect } from "react"

function Baloon(props) {
	const [ active, setActive ] = useState(true)
	const [ expiring, setExpiring ] = useState(false)

	if(!active)
		props.onClose(props.def.key)

	useEffect(() => {
		if(props.def.stat && !expiring) {
			setExpiring(true)
			setTimeout(() => {
				if(active)
					setActive(false)
			}, 2500)
		}
	})

	if(!active)
		return null
	else {
		const clName = 'notification clickable '+( props.def.stat ? 'is-success' : 'is-danger' )
		return (
<div className={clName} onClick={() => setActive(false) }>
	<button className="delete"></button>
	{props.def.txt}
</div>
		)
	}
}

export default function Speaker(props) {
	let comm = props.comm.map(item =>
		<Baloon key={item.key} def={item} onClose={props.onClose} />
	)
	return (
		<div className="notification-area">{comm}</div>
	)
}
