import React, { useEffect } from "react"
import AutoC from '../AutoC'
import FormCustomer from './form/Customer'

export default function ScreenCartCustomer(props) {
	const searchId = props.customer.id ? props.customer.id : null
	
	function setSearchId(id) {
		if(id)
			props.setCustomer({ id })
		else
			props.setCustomer({})
	}

	function submit() {
		props.nav(null, 'cart-confirm')
	}

	function apiSearch(clue) {
		return props.api.call('search_customer', {clue})
	}
	
	useEffect(() => {
		if(Object.keys(props.customer).length===1 && props.customer.hasOwnProperty('id'))
			props.api.call('get_customer', {id:props.customer.id}).then(props.setCustomer)
	}, [props.customer])

	return (
<>
	<h1>Selezione cliente</h1>
	<AutoC onClick={setSearchId} id={searchId} src={apiSearch}>{ (props.customer.id && props.customer.name) ? props.customer.name : ''}</AutoC>
	<div className="client-form form">
		<FormCustomer data={props.customer} setData={props.setCustomer} ro={props.customer._ro} />
		<div className="field is-horizontal">
			<div className="field-label"></div>
			<div className="field-body">
				<div className="field">
					<div className="control">
						<button className="button is-info" onClick={submit}>
							<span className="icon is-small"><i className="fas fa-arrow-right"></i></span> <span>Prosegui</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</>
	)
}
