import React from 'react';

function Step(props) {
	let iconCode = ''
	if(props.icon)
		iconCode = (
<span className="icon is-small">
	<i className={props.icon} aria-hidden="true"></i>
</span>
		)

	if(props.isActive)
		return (
<li className="is-active">
	<a href="#">
		{iconCode}
		<span>{props.label}</span>
	</a>
</li>
		)
	else
		return (
<li>
	<a href="#" onClick={e => props.onNav(e, props.lnk)}>
		{iconCode}
		<span>{props.label}</span>
	</a>
</li>
		)
}

export default function Breadcrumb(props) {
	let isActive = !props.path || !props.path.length
	let steps = [ <Step label="Prodotti" key="_" onNav={props.onNav} icon="fas fa-warehouse" isActive={isActive} /> ]

	if(props.path) {
		let pathCount = 0
		for(const pathStep of props.path) {
			isActive = ++pathCount === props.path.length
			steps.push(
				<Step label={pathStep.label} key={pathStep.key} lnk={pathStep.lnk} icon={pathStep.icon} onNav={props.onNav} isActive={isActive} />
			)
		}
	}

	return (
<nav className="breadcrumb" aria-label="breadcrumbs">
	<ul>{steps}</ul>
</nav>
	)
}
