import React, { useState, useEffect } from "react"

export default function ScreenQuoteDone(props) {
	const [ quoteNum, setQuoteNum ] = useState(null)
	const [ quoteId, setQuoteId ] = useState(null)
	const [ email, setEmail ] = useState('')
	const [ pdf, setPdf ] = useState('')
	
	useEffect(() => {
		const editId = props.editQuote ? props.editQuote.id : null
		props.api.call('set_quote', { editId, cart:props.cart, shipping:props.cartShip, discount:props.cartDiscount, customer:props.cartCustomer }).then(ret => {
			if(!ret)
				setQuoteNum('ERR')
			else {
				setQuoteNum(ret.num)
				setQuoteId(ret.id)
				if(ret.pdf)
					setPdf(ret.pdf)
				if(ret.email)
					setEmail(ret.email)
				props.onCartReset()
			}
		})
	}, [])

	function openLink(e, path) {
		e.preventDefault()
		window.open(path)
	}
	function sendEmail(e) {
		props.api.call('email_quote', { id:quoteId, rcpt:email })
	}

	const quoteDone = quoteNum && quoteNum!=='ERR'
	const quoteGfx = quoteDone ? quoteNum :
		( quoteNum<0 ? <i className="fas fa-exclamation-triangle"></i> : <i className="fas fa-circle-notch fa-spin"></i> )
	
	return (
<>
	<h1>Generato preventivo n. {quoteGfx}</h1>

	{ pdf &&
		<button className="button is-primary" onClick={e => openLink(e, pdf)}>
			<span className="icon"><i className="fas fa-file-pdf"></i></span>
			<span>Scarica PDF</span>
		</button>
	}

	{ quoteDone && (
		<div className="field">
			<label className="label">Invia per e-mail</label>
			<div className="field has-addons">
				<div className="control ">
					<input className="input" type="email" placeholder="E-mail Destinatario" value={email} onChange={e => setEmail(e.target.value)} />
				</div>
				<div className="control">
					<button className="button is-info" onClick={sendEmail}>
						<span className="icon"><i className="fas fa-paper-plane"></i></span>
						<span>Invia</span>
					</button>
				</div>
			</div>
		</div>
	)}
</>
	)
}
