import React, { useState, useEffect } from "react"
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Breadcrumb from './Breadcrumb';
//import queryString from 'query-string';

import ScreenCatalog from './screen/Catalog';
import ScreenProduct from './screen/Product';
import ScreenLogin from './screen/Login';
import ScreenCart from './screen/Cart';
import ScreenCartCustomer from './screen/CartCustomer';
import ScreenCartConfirm from './screen/CartConfirm';
import ScreenQuotes from './screen/Quotes';
import ScreenStats from './screen/Stats';
import ScreenAddrbook from './screen/Addrbook';
import ScreenCustomerEdit from './screen/CustomerEdit';
import ScreenSpeaker from './screen/Speaker';
import ScreenQuoteDone from './screen/QuoteDone';

import { v4 as uuidv4 } from 'uuid'
import { mkPath, catSubs, catVars, scanVar } from './lib/catalog'
import { colorVPrice } from './Colorpicker'

const screenDefault = ['cat']
const serviceAddr = 'https://servizi.ahtitalia.it'
//const serviceAddr = 'http://aht.dev.dlablondon.com'

function Api(jwt, speaker) {
	this.jwt = jwt

	this.updJwt = (jwt) => {
		this.jwt = jwt
	}

	this.call = (tag, p) => {
		if(!p)
			p = {}
		const opts = {
			method:		'POST',
			cache:		'no-cache',
			headers:	{
				'Content-Type': 'application/json',
			},
			body:		JSON.stringify({ tag, p }),
		}
		if(this.jwt)
			opts.headers['Authorization'] = 'Bearer '+this.jwt
		return fetch('https://preventivi.ahtitalia.it/api.php', opts)
			.then(res => res.json())
			.then(res => {
				//console.log('apiRet', res)
				let speakerTxts = []
				if(res.dbgs)
					res.dbgs.forEach(txt => console.log('API-DBG ['+tag+']', txt))
				if(res.errs)
					res.errs.forEach(txt => speakerTxts.push({ stat:0, txt }))
				if(res.msgs)
					res.msgs.forEach(txt => speakerTxts.push({ stat:1, txt }))

				if(speakerTxts.length)
					speaker(speakerTxts)

				if(res._stat)
					return res.data
		})
	}
}

const emptyArchive = { cats:[], vars:[], prods:{} }
export default function App() {
	const [ jwt, setJwt ] = useState()
	const [ jwtAdmin, setJwtAdmin ] = useState()
	const [ archive, setArchive ] = useState(emptyArchive)
	const [ screenCur, setScreenCur ] = useState(screenDefault)
	const [ cart, setCart ] = useState([])
	const [ cartShip, setCartShip ] = useState(90)
	const [ cartDiscount, setCartDiscount ] = useState(null)
	const [ cartCustomer, setCartCustomer ] = useState({})
	const [ comm, setComm ] = useState([])
	const [ menuBurger, setMenuBurger ] = useState(false)
	const [ editQuote, setEditQuote ] = useState()

	const api = new Api(jwt, speaker)

	const clearJwt = () => {
		setJwt(null)
		localStorage.removeItem('jwtToken')
		setArchive(emptyArchive)
		if(serviceAddr)
			window.location.href = serviceAddr
	}

	const setupJwt = token => {
		api.updJwt(token)
		setJwt(token)
		api.call('session_refresh').then(ret => {
			if(ret) {
				setArchive(ret.archive)
				setJwtAdmin(ret.is_admin)
			}
			else
				clearJwt()
		})
	}

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		if(params.has('_tk')) {
			const token = params.get('_tk')
			localStorage.setItem('jwtToken', token)
			setupJwt(token)
		}
		else if(!jwt && serviceAddr)
			window.location.href = serviceAddr
		if(window.location.pathname && window.location.pathname==='/archive')
			nav(null, 'quotes')
	}, [])

	if(!jwt) {
		let jwtStore = localStorage.getItem('jwtToken')
		if(jwtStore)
			setupJwt(jwtStore)
	}

	function nav(e, screen) {
		if(!screen)
			screen = screenDefault
		else if(typeof(screen)==='string')
			screen = screen.split('/')

		if(e) {
			e.preventDefault()
			e.stopPropagation()
		}
		setScreenCur(screen)
		setMenuBurger(false)
	}


	function updAuth(user, pass) {
		if(jwt && user===null) {
			/*
			setJwt(null)
			localStorage.removeItem('jwtToken')
			setArchive(emptyArchive)
			*/
			clearJwt()
			//speaker(true, 'Logout eseguito')
		}
		else if(user || pass) {
			api.call('login', { user, pass }).then(ret => {
				if(ret) {
					localStorage.setItem('jwtToken', ret.jwt)
					setJwt(ret.jwt)
					setJwtAdmin(ret.is_admin)
					setArchive(ret.archive)
					setScreenCur(screenDefault)
				}
			})
		}
	}

	function speaker(stat, txt) {
		if(Array.isArray(stat)) {
			stat.forEach(item => item.key=uuidv4())
			setComm( comm.concat(stat) )
		}
		else
			setComm( comm.concat([{ key:uuidv4(), stat, txt }]) )
	}

	function speakerDel(key) {
		setComm( comm.filter(item => item.key!==key) )
	}

	const cartMgm = {
		add: (vid, colorV, colorPC)=>{
			let cartKey = vid + '#' + colorV + '#' + colorPC
			let sameIdx = cart.findIndex(item => item.key===cartKey)
			let vdef = archive.vars.find(variant => variant.id===vid)
			let vmulti = parseInt(vdef.multiplier)
			if(sameIdx>=0) {
				let cartTmp = [...cart]
				cartTmp[sameIdx].qty += vmulti
				setCart(cartTmp)
			}
			else {
				let item = {
					id: vid,
					key: cartKey,
					colorV,
					colorPC,
					qty: vmulti,
					label: archive.prods[vdef.product].name+' - '+archive.cats.find(cat => cat.tag===vdef.cat).name,
					price: (colorVPrice(colorV) + parseInt(archive.prods[vdef.product].price))/100,
					img: archive.prods[vdef.product].img,
					kit: vdef.kit_price>0,
					kit_price: vdef.kit_price,
				}
				setCart(cart.concat(item))
			}
			speaker(1, 'Prodotto aggiunto al carrello')
		},
		chQty: (key, qty, tmp)=>{
			qty = parseInt(qty)
			let idx = cart.findIndex(item => item.key===key)
			if(!tmp && cart[idx].id) {
				const multi = archive.vars.find(variant => variant.id===cart[idx].id).multiplier
				qty += qty % multi
			}
	
			let cartTmp = [...cart]
			if(qty>0)
				cartTmp[idx].qty = qty
			else
				cartTmp.splice(idx, 1)
			setCart(cartTmp)
		},
		chKit: (key, active)=>{
			let idx = cart.findIndex(item => item.key===key)
			let cartTmp = [...cart]
			cartTmp[idx].kit = !!active
			setCart(cartTmp)
		},
		chColor: (key, colorV, colorPC)=>{
			let idx = cart.findIndex(item => item.key===key)
			if(colorV===null)
				colorV = cart[idx].colorV
			if(colorPC===null)
				colorPC = cart[idx].colorPC
			let cartKey = cart[idx].id + '#' + colorV + '#' + colorPC
			let sameIdx = cart.findIndex(item => item.key===cartKey)
			if(sameIdx>=0)
				speaker(0, 'Combinazione prodotto/colore già presente nel carrello')
			else {
				let cartTmp = [...cart]
				let vdef = archive.vars.find(variant => variant.id===cart[idx].id)

				cartTmp[idx].key = cartKey
				cartTmp[idx].colorV = colorV
				cartTmp[idx].colorPC = colorPC
				cartTmp[idx].price = (colorVPrice(colorV) + parseInt(archive.prods[vdef.product].price))/100
				setCart(cartTmp)
			}
		},
		edit: (label, price, key=null)=>{
			let cartTmp = [...cart]
			let idx = key ? cart.findIndex(item => item.key===key) : -1
			if(idx<0)
				cartTmp.push({
					key: uuidv4(),
					qty: 1,
					label,
					price,
					kit: false,
					kit_price: 0,
				})
			else {
				cartTmp[idx].label = label
				cartTmp[idx].price = price
			}
			setCart(cartTmp)
		},
		ship: (price)=>{
			price = parseFloat(price)
			if(isNaN(price))
				price = ''
			else if(price<0)
				price = 0
			setCartShip(price)
		},
		discount: (perc)=>{
			perc = parseFloat(perc)
			if(isNaN(perc))
				perc = ''
			else if(perc<0)
				perc = 0
			else if(perc>41.5)
				perc = 41.5
			setCartDiscount(perc)
		},
		reset: ()=>{
			setCart([])
			setCartShip(90)
			setCartDiscount(null)
			setCartCustomer({})
			setEditQuote(null)
		},
		revise: (id)=>{
			api.call('get_quote', { id }).then(ret => {
				setEditQuote({ id, num:ret.num })
				setCart(ret.cart.map(cartItem => {
					if(cartItem.id)
						cartItem.id = cartItem.id.toString()
					if(cartItem.kit_price)
						cartItem.kit_price = cartItem.kit_price.toString()
					cartItem.key = cartItem.id + '#' + cartItem.colorV + '#' + cartItem.colorPC
					return cartItem
				}))
				setCartShip(ret.shipping)
				setCartDiscount(ret.discount)
				setCartCustomer(ret.customer)
				setScreenCur(screenDefault)
			})
		},
		unrevise: ()=>{
			setEditQuote(null)
			setCart([])
			setCartShip(90)
			setCartDiscount(null)
			setCartCustomer({})
			setScreenCur(screenDefault)
			speaker(true, 'Revisione annullata')
		},
	}

	function draw(screen) {
		let path
		switch(screen[0]) {
			case 'login':
				return {
					//content: <ScreenLogin updAuth={updAuth} speaker={speaker} />,
					content: null,
					cartHide: true
				}
			case 'cart':
				return {
					content:<ScreenCart items={cart} ship={cartShip} discount={cartDiscount} mgm={cartMgm} nav={nav} vars={archive.vars} />,
					breadcrumb: [ {key:'cart', label:'Carrello', icon:"fas fa-shopping-cart"} ]
				}
			case 'prod':
				path = mkPath(archive, screen[1])
				return {
					content:<ScreenProduct cat={screen[1]} variant={scanVar(archive, screen[1], screen[2])} prodCode={screen[2]} prod={archive.prods[screen[2]]} cartAdd={cartMgm.add} />,
					breadcrumb: path.concat([ { key:'prod', label:archive.prods[screen[2]].name } ]),
					sidebar: <Sidebar tree={archive.cats} nav={nav} path={path} />,
				}
			case 'sel-customer':
				return {
					content: <ScreenCartCustomer nav={nav} api={api} customer={cartCustomer} setCustomer={setCartCustomer} />,
					breadcrumb: [
						{key:'cart', label:'Carrello', lnk:'cart', icon:"fas fa-shopping-cart"},
						{key:'sel-customer', label:'Selezione cliente', icon:"fas fa-user"},
					]
				}
			case 'cart-confirm':
				return {
					content: <ScreenCartConfirm items={cart} ship={cartShip} discount={cartDiscount} nav={nav} customer={cartCustomer} vars={archive.vars} mgm={cartMgm} />,
					breadcrumb: [
						{key:'cart', label:'Carrello', lnk:'cart', icon:"fas fa-shopping-cart"},
						{key:'sel-customer', label:'Selezione cliente', icon:"fas fa-user", lnk:"sel-customer"},
						{key:'cart-confirm', label:'Conferma carrello', icon:"fas fa-check"},
					]
				}
			case 'quote-done':
				return {
					content: <ScreenQuoteDone editQuote={editQuote} cart={cart} cartShip={cartShip} cartDiscount={cartDiscount} cartCustomer={cartCustomer} api={api} onCartReset={cartMgm.reset} />,
					breadcrumb: [
						{key:'cart', label:'Carrello', lnk:'cart', icon:"fas fa-shopping-cart"},
						//{key:'sel-customer', label:'Selezione cliente', icon:"fas fa-user", lnk:'sel-customer'},
						{key:'quote-done', label:'Preventivo completato', icon:"fas fa-file-invoice"},
					]
				}
				/*
			case 'addrbook':
				return {
					content: <ScreenAddrbook api={api} nav={nav} jwtAdmin={jwtAdmin} />,
					breadcrumb: [ {key:'addrbook', label:'Clienti', icon:"fas fa-address-book"} ]
				}
			case 'customer-edit':
				return {
					content: <ScreenCustomerEdit id={screen[1]} api={api} nav={nav} />,
					breadcrumb: [
						{key:'addrbook', label:'Clienti', icon:"fas fa-address-book", lnk:'addrbook'},
						{key:'customer', label: screen.length>2 ? screen[2] : 'Nuovo cliente', icon:"fas fa-user"},
					]
				}
				*/
			case 'quotes':
				return {
					content: <ScreenQuotes api={api} nav={nav} cartMgm={cartMgm} />,
					breadcrumb: [ {key:'quotes', label:'Archivio', icon:"fas fa-archive"} ]
					//breadcrumb: [ {key:'quotes', label:'Preventivi', icon:"fas fa-file-invoice"} ]
				}
				/*
			case 'stats':
				return {
					content: <ScreenStats api={api} />,
					breadcrumb: [ {key:'stats', label:'Statistiche', icon:"fas fa-calculator"} ]
				}
				*/
			default:
				path = mkPath(archive, screen[1])
				const subCats = catSubs(archive, path)
				const subVars = catVars(archive, screen[1])
				return {
					content: <ScreenCatalog cats={subCats} vars={subVars} prods={archive.prods} nav={nav} cartAdd={cartMgm.add} />,
					breadcrumb: path ? path : null,
					sidebar: <Sidebar tree={archive.cats} nav={nav} path={path} />
				}
		}
	}

	let screenDraw = screenCur
	if(!jwt)
		screenDraw = ['login']
	else if(!cart.length && screenDraw[0]==='sel-customer') {
		nav(null, 'cart')
		speaker(0, 'Inserire almeno un articolo per poter proseguire')
	}
	const {content, breadcrumb, sidebar, cartHide} = draw(screenDraw, speaker, nav, updAuth, cart)
	
	return (
<>
	<Navbar nav={nav} isAuth={jwt ? true:false} jwtAdmin={jwtAdmin} updAuth={updAuth} cart={cartHide ? false:cart} burger={menuBurger} setBurger={setMenuBurger} />
	{ editQuote &&
		<section className="section topbar-edit-warning topbar-edit-warning has-background-warning">
			<div className="level">
				<div className="level-item">Stai modificando il preventivo {editQuote.num}</div>
				<div className="level-item">
					<button className="button" onClick={cartMgm.unrevise}>Annulla</button>
				</div>
			</div>
		</section>
	}
	<section className="section">
		<div className="container">
			<div className="columns row-reverse">
				<div className="column page-content">
					{breadcrumb!==undefined && <Breadcrumb onNav={nav} path={breadcrumb} />}
					<div className="content">
						{content}
					</div>
				</div>
				{sidebar}
			</div>
		</div>
	</section>
	<ScreenSpeaker comm={comm} onClose={speakerDel} />
</>
	)
}
