import React, { useState, useEffect } from "react"

export default function AutoC(props) {
	const [ clue, setClue ] = useState(props.children)
	const [ searching, setSearching ] = useState(false)
	const [ hints, setHints ] = useState([])

	function onClear(e) {
		setSearching(false)
		props.onClick(null)
	}
	function onChange(e) {
		setSearching(true)
		setClue(e.target.value)
	}
	function onClick(e, id, label) {
		props.onClick(id)
		setSearching(false)
	}

	useEffect(() => {
		if(searching && clue)
			props.src(clue).then(ret => setHints(ret.data) )
		else
			setHints([])
	}, [props.id, clue, props.children])

	return (
		<div className="autocomplete-search">
			<div className="field has-addons">
				<div className="control is-expanded">
					<input className="input" type="text" placeholder="Trova cliente" value={ searching ? clue : props.children } onChange={onChange} />
				</div>
				<div className="control">
					<button className="button is-danger" onClick={onClear}>
						<span className="icon"><i className="fas fa-times"></i></span>
					</button>
				</div>
				<div className="control">
					<span className="button is-static">
						<span className="icon"><i className="fas fa-search"></i></span>
					</span>
				</div>
			</div>
			{ hints.length>0 &&
				<div className="dropdown">
					<ul>
						{ hints.map(hint =>
							<li className="clickable" key={hint.id} onClick={(e) => onClick(e, hint.id, hint.label)}>{hint.label}</li>
						)}
					</ul>
				</div>
			}
		</div>
	)
}
