import React from "react"
//import React, { useState, useEffect } from "react"

const radius = 2

export function calcPager(count, pageSize, pageCur, setPageCur) {
	let pageMax = Math.ceil(count/pageSize)
	if(pageMax<1)
		pageMax = 1

	let cur
	if(!pageCur || pageCur<1)
		cur = 1
	else if(pageCur>pageMax)
		cur = pageMax
	else
		cur = pageCur

	if(cur!==pageCur)
		setPageCur(cur)

	//return [ cur, pageMax ]
	return pageMax
}

function Btn(props) {
	const num = parseInt(props.children)
	const aria = "Goto page "+num
	let clName = "pagination-link"
	if(props.cur===num)
		clName += " is-current"

	return (
<li>
	<button className={clName} aria-label={aria} onClick={() => props.set(num)}>{num}</button>
</li>
	)
}

export default function Pager(props) {
	const pagePrev = props.cur<2 ? 1 : props.cur-1
	const pageNext = props.cur>=props.max ? props.max : props.cur+1

	let rangeMin = props.cur-radius
	if(rangeMin<2)
		rangeMin = 2
	let rangeMax = props.cur+radius
	if(rangeMax>=props.max)
		rangeMax = props.max-1
	
	let range
	if(rangeMin<=rangeMax) {
		range = []
		for(let i=rangeMin; i<=rangeMax; i++)
			range.push(i)
		range = range.map(num => <Btn key={num} cur={props.cur} set={props.set}>{num}</Btn>)
	}

	return (
<nav className="pagination is-centered" role="navigation" aria-label="pagination">
	<button className="pagination-previous" onClick={() => props.set(pagePrev)}>Pag. Precedente</button>
	<button className="pagination-next" onClick={() => props.set(pageNext)}>Pag. Successiva</button>
	<ul className="pagination-list">
		<Btn key="1" cur={props.cur} set={props.set}>1</Btn>
		{ rangeMin>2 && <li><span className="pagination-ellipsis">&hellip;</span></li> }
		{range}
		{ rangeMax<(props.max-1) && <li><span className="pagination-ellipsis">&hellip;</span></li> }
		{ props.max>1 && <Btn key={props.max} cur={props.cur} set={props.set}>{props.max}</Btn> }
	</ul>
</nav>
	)
}
