import React from 'react';

function BlkText({ placeholder, label, value, setValue }) {
	if(!value)
		value = ''
	if(!placeholder)
		placeholder = label

	return (
		<div className="field">
			<div className="control">
				<input className="input" type="text" placeholder={placeholder} value={value} onChange={e => setValue(e.target.value)} />
			</div>
		</div>
	)
}

function BlkSelect({ options, value, setValue }) {
	return (
		<div className="select">
			<select value={value} onChange={e => setValue(e.target.value)}>
				{ options.map(opt => <option key={opt.id} value={opt.id}>{opt.label}</option>) }
			</select>
		</div>
	)
}

function BlkChecks({ options, value, setValue }) {
	if(!value)
		value = []
	function onChange(id) {
		if(value.includes(id))
			setValue(value.filter(valItem => valItem!==id))
		else
			setValue(value.concat(id))
	}

	return options.map(opt => (
		<label className="checkbox column is-narrow" key={opt.id}>
			<input type="checkbox" checked={value.includes(opt.id)} onChange={() => onChange(opt.id)} /> {opt.label}
		</label>
	))
}

export default function Search(props) {
	function onChange(e, sub) {
		if(!sub)
			props.onChange(e.target.value)
		else {
			let tmp = { ...props.value }
			tmp[sub] = e.target.value
			props.onChange(tmp)
		}
	}

	let bodyClass = 'field-body'
	let field,value
	switch(props.type) {
		case 'date-int':
			value = { from:'', to:'' }
			if(props.value && typeof(props.value)==='object') {
				value.from = props.value.from ? props.value.from : ''
				value.to = props.value.to ? props.value.to : ''
			}

			field = (
				<div className="field has-addons">
					<div className="control"><span className="button is-static">Da:</span></div>
					<div className="control has-icons-left">
						<input className="input" type="date" placeholder="dd/mm/aaaa" value={value.from} onChange={e => onChange(e, 'from')} />
						<span className="icon is-small is-left">
							<i className="fas fa-calendar"></i>
						</span>
					</div>
					<div className="control"><span className="button is-static">A:</span></div>
					<div className="control has-icons-left">
						<input className="input" type="date" placeholder="dd/mm/aaaa" value={value.to} onChange={e => onChange(e, 'to')} />
						<span className="icon is-small is-left">
							<i className="fas fa-calendar"></i>
						</span>
					</div>
				</div>
			)
			break
		case 'select':
			field = <BlkSelect value={props.value} setValue={props.onChange} options={props.options} />
			break
		case 'checks':
			field = <BlkChecks value={props.value} setValue={props.onChange} options={props.options} />
			break
		default:
			field = <BlkText value={props.value} placeholder={props.placeholder} label={props.label} setValue={props.onChange} />
	}

	return (
		<div className="field is-horizontal">
			<div className="field-label"><label className="label">{props.label}</label></div>
			<div className={bodyClass}>
				{field}
			</div>
		</div>
	)

}
