import React from 'react'
import { colorsV, colorsPC } from './colors'

function Color({ color, colorCheck, RAL, isChecked, onClick }) {
	return (
		<div className="level-item is-narrow" onClick={onClick}>
			<span className="clickable colorbox" style={{backgroundColor:color, color:colorCheck}}>
				{ isChecked &&
					<i className="fas fa-check"></i>
				}
				{ RAL }
			</span>
		</div>
	)
}

export const defaultColorV = colorsV.find(color => color.default).key
export const defaultColorPC = colorsPC.find(color => color.default).key

export function colorVPrice(colorV) {
	const def = colorsV.find(cStep => colorV===cStep.key)
	return (def && def.price) ? parseInt(def.price) : 0
}

export default function Colorpicker({ colorV, setColorV, colorPC, setColorPC }) {
	if(!colorV || !colorsV.filter(cDef => cDef.key===colorV).length)
		colorV = defaultColorV
	if(!colorPC || !colorsPC.filter(cDef => cDef.key===colorPC).length)
		colorPC = defaultColorPC

	const gfxColorsV = colorsV.map(colorDef => (
		<Color key={colorDef.key} color={colorDef.code} colorCheck={colorDef.colorCheck} RAL={colorDef.ral} isChecked={colorV===colorDef.key} onClick={() => setColorV(colorDef.key)} />
	))
	const gfxColorsPC = colorsPC.map(colorDef => (
		<Color key={colorDef.key} color={colorDef.code} colorCheck={colorDef.colorCheck} RAL={colorDef.ral} isChecked={colorPC===colorDef.key} onClick={() => setColorPC(colorDef.key)} />
	))

	return (
		<>
		<div className="level colorpicker is-mobile">
			<div className="item-label level-item is-narrow">Vasca</div>
			{gfxColorsV}
		</div>
		<div className="level colorpicker is-mobile">
			<div className="item-label level-item is-narrow">Paracolpi</div>
			{gfxColorsPC}
		</div>
		</>
	)
}
