import React from "react"

function Head(props) {
	const cols = props.def.map(col => {
		const className = col.type ? 'type-'+col.type : ''
		return <th key={col.key} className={className}>{col.label}</th>
	})
	return (
		<thead>
			<tr>{cols}</tr>
		</thead>
	)
}

function Btn(props) {
	function onClick(e) {
		props.actions[props.actionTag](e, props.rowId)
	}

	if(props.disabled)
		return null
	else
		return (
			<button className="button" onClick={onClick}>
				<span className="icon">
					<i className={props.icon}></i>
				</span>
			</button>
		)
}

function Btns(props) {
	return (
		<>
			{ props.children.map(action =>
				<Btn actions={props.actions} actionTag={action.key} key={action.key} rowId={props.rowId} icon={action.icon} disabled={action.disabled} />
			)}
		</>
	)
}

function Cell(props) {
	function onSelectChange(e) {
		if(props.def.selectAction)
			props.actions[props.def.selectAction](e, props.rowId)
	}

	let data
	if(props.def.data && typeof(props.def.data)==='function')
		data = props.def.data(props.children)
	else if(props.def.selectOpts)
		data = (
			<select value={props.children[props.tag]} onChange={onSelectChange}>
				{ props.def.selectOpts.map(opt => <option value={opt.id} key={opt.id}>{opt.label}</option>)}
			</select>
		)
	else {
		data = props.children[props.tag]
		if(props.def.type==='date') {
			let dt = new Date(data*1000)
			data = dt.toLocaleDateString()
		}
		else if(props.def.type==='money') {
			data = (data/100).toFixed(2).toString().split('.')
			data = data[0].split("").reverse().join("").match(/.{1,3}/g).join(' ').split("").reverse().join("") + '.' + data[1]
			data += ' €'
		}
	}
	
	const className = props.def.type ? 'type-'+props.def.type : ''

	return (
		<td className={className}>
			{data}
			{ props.def.actions && <Btns actions={props.actions} rowId={props.rowId}>{props.def.actions}</Btns> }
		</td>
	)
}

function Row(props) {
	return (
		<tr>
			{ props.def.map(col =>
				<Cell key={col.key} tag={col.key} def={col} actions={props.actions} rowId={props.children.id}>{props.children}</Cell>
			)}
		</tr>
	)
}

export default function Table(props) {
	const rows = props.children.map(row => <Row def={props.def} key={row.id} actions={props.actions}>{row}</Row>)
	const summary = props.summary ? <Row def={props.def} actions={props.actions}>{props.summary}</Row> : null

			//<tfoot><tr><th scope="row">Totale</th><td className="type-money">0.00 €</td><td>0</td><td className="type-money">0.00 €</td><td>0</td><td className="type-money">0.00 €</td><td>0</td></tr></tfoot>
	return (
		<div className="table-container"><table className="table">
			<Head def={props.def} />
			<tbody>{rows}</tbody>
			{ summary && <tfoot>{summary}</tfoot> }
		</table></div>
	)
}
