import React from 'react';
import { dspMoney } from '../lib/vconv'

const defImgCat = 'https://preventivi.ahtitalia.it/img/default_260x260.png'
const defImgProd = 'https://preventivi.ahtitalia.it/img/default_260x260.png'

function Cat(props) {
	let img = props.img ? props.img : defImgCat
	return (
<div className="column box-category">
	<div className="clickable" onClick={e => props.nav(e, 'cat/'+props.tag)}>
		<figure className="image"><img src={img} alt="immagine categoria" /></figure>
		<div className="box-overlay">
			<button className="showItem">{props.label}</button>
		</div>
	</div>
</div>
	)
}

function Prod(props) {
	let price = dspMoney(props.prod.price/100)
	let img = props.prod.img ? props.prod.img : defImgProd

	function cartAdd(e) {
		e.preventDefault()
		e.stopPropagation()
		props.cartAdd(props.id)
	}

	return (
<div className="column box-product">
	<div className="box-product-content">
		<div className="clickable" onClick={(e) => props.nav(e, 'prod/'+props.cat+'/'+props.prodCode)}>
			<figure className="image"><img src={img} alt="immagine prodotto" /></figure>
			<div className="box-overlay">
				{/*<button className="add-to-cart-btn" title="Aggiungi al carrello" onClick={cartAdd}>
					<span className="icon"><i className="fas fa-cart-plus"></i></span>
				</button>*/}
			</div>
		</div>
		<div className="prod-info">
			<h1>{props.prod.name}</h1>
			<div className="price">{price}</div>
		</div>
	</div>
</div>
	)
}

export default function ScreenCatalog(props) {
	let cats,prods
	if(props.cats.length) {
		cats = (
			<>
				<h1 className="current-cat-title">Categorie</h1>
				<div className="columns is-multiline">
					{ props.cats.map(cat => <Cat key={cat.tag} tag={cat.tag} label={cat.name} img={cat.img} nav={props.nav} />) }
				</div>
			</>
		)
	}
	if(props.vars.length) {
		prods = (
			<>
				<h2 className="prod-grid-title">Prodotti</h2>
				<div className="columns is-multiline">
					{ props.vars.map(item => <Prod
									key={item.id}
									id={item.id}
									cat={item.cat}
									nav={props.nav}
									prodCode={item.product}
									prod={props.prods[item.product]}
									cartAdd={props.cartAdd}
								/>) }
				</div>
			</>
		)
	}

	return <>{cats}{prods}</>
}
