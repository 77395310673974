import React, { useState, useEffect } from "react"
import Table from '../Table'
import Pager, { calcPager } from '../Pager'
import Search from '../Search'

const srcStatusOptions = [
	{ id:'a', label:'Accettato' },
	{ id:'w', label:'In attesa' },
	{ id:'r', label:'Rifiutato' },
]

export default function ScreenQuotes(props) {
	const [ pageCur, setPageCur ] = useState(1)
	const [ dbq, setDbq ] = useState({data:[], count:0})
	const [ acceptedLast, setAcceptedLast ] = useState()
	const [ mgmcodeEdit, setMgmcodeEdit ] = useState()
	
	const [ srcNum, setSrcNum ] = useState()
	const [ srcCustomer, setSrcCustomer ] = useState()
	const [ srcDate, setSrcDate ] = useState()
	const [ srcUser, setSrcUser ] = useState(null)
	const [ srcStatus, setSrcStatus ] = useState([ 'a', 'w' ])

	useEffect(() => {
		props.api.call('scan_quotes', {page:pageCur, src_num:srcNum, src_customer:srcCustomer, src_date:srcDate, src_user:srcUser, src_status:srcStatus}).then(setDbq)
	}, [pageCur, props.api, srcNum, srcCustomer, srcDate, srcUser, srcStatus])

	function userChange(e, id) {
		const uid = e.target.value
		props.api.call('chuser_quote', {id, uid})
	}
	function statusChange(e, id) {
		const tag = e.target.value
		props.api.call('status_quote', {id, tag})
		if(tag==='a')
			setAcceptedLast(id)
		else if(id===acceptedLast)
			setAcceptedLast(null)
	}
	function mgmcodeSave() {
		if(mgmcodeEdit) {
			props.api.call('mgmcode_quote', {id:mgmcodeEdit.id, code:mgmcodeEdit.val})
			setMgmcodeEdit(null)
		}
	}
	function sendCCare() {
		props.api.call('email_customercare', {id:acceptedLast})
		setAcceptedLast(null)
	}

	const tabDef = [
		{ key:'num',		label:'Numero' },
		{ key:'ts',		label:'Data',	type:'date' },
		{ key:'customer',	label:'Cliente', data:(row) => {
			return (
				<>
				{row.customer_name}<br />
				<span className="is-size-7">{row.customer_cdc}</span>
				</>
			)
		}},
		
		{ key:'total',		label:'Totale',	type:'money' },
		{ key:'status_flag' },
		{ key:'status',		label:'Stato',	data:(row) => {
			return (
					<select value={row.status} onChange={e => statusChange(e, row.id)}>
						<option value="a">Accettato</option>
						<option value="w">In attesa</option>
						<option value="r">Rifiutato</option>
					</select>
			)
		}},
		{ key:'mail_ccare',	data:(row) => {
			if(row.id===acceptedLast)
				return (
					<button className="button" onClick={sendCCare}>
						<span className="icon">
							<i className="fas fa-envelope"></i>
						</span> <span>Customer care</span>
					</button>
				)
		}},
		{ key:'mgmcode',	label:'Num. ordine', data:(row) => {
			if(row.status==='a') {
				if(mgmcodeEdit && mgmcodeEdit.id===row.id)
					return (
						<div className="field has-addons">
							<div className="control">
								<input className="input is-small" type="text" value={mgmcodeEdit.val ? mgmcodeEdit.val : ''} onChange={e => setMgmcodeEdit({ id:row.id, val:e.target.value })} />
							</div>
							<button className="button is-success is-small" onClick={mgmcodeSave}>
								<span className="icon">
									<i className="fas fa-check"></i>
								</span>
							</button>
							<button className="button is-danger is-small" onClick={() => setMgmcodeEdit(null)}>
								<span className="icon">
									<i className="fas fa-times"></i>
								</span>
							</button>
						</div>
					)
				else
					return (
						<span className="clickable" onClick={() => setMgmcodeEdit({ id:row.id, val:row.mgm_code })}>
							{row.mgm_code ? row.mgm_code : '-' } <span className="icon has-text-grey"><i className="fas fa-pencil-alt"></i></span>
						</span>
					)
			}
		}},
		{ key:'user',	label:'Commerciale', data:row => {
			if(!dbq.users)
				return '-'
			else {
				return (
					<select value={row.user} onChange={e => userChange(e, row.id)}>
						{ dbq.users.map(user => (
							<option value={user.id}>{user.label}</option>
						)) }
					</select>
				)
			}
		}},
		{ key:'ops',	actions:[
			{ key:'pdf',	icon:'fas fa-file-pdf' },
			{ key:'edit',	icon:'fas fa-edit' },
		]},
	]
	if(dbq.data)
		dbq.data.forEach(dataRow => {
			switch(dataRow.status) {
				case 'a':
					dataRow.status_flag = <span className="icon has-text-success"><i className="fas fa-check"></i></span>
				break
				case 'r':
					dataRow.status_flag = <span className="icon has-text-danger"><i className="fas fa-times"></i></span>
				break
				case 'w':
				default:
					dataRow.status_flag = <span className="icon has-text-warning"><i className="fas fa-hourglass"></i></span>
			}
		})

	const actions = {
		statusUpd: (e, id) => {
			props.api.call('status_quote', {id, tag: e.target.value})
		},
		pdf: (e, id) => {
			window.open(dbq.data.find(row => row.id===id).pdf)
		},
		edit: (e, id) => {
			props.cartMgm.revise(id)
		},
	}
	const pageMax = calcPager(dbq.count, dbq.pagesize, pageCur, setPageCur)

	function exportCsv(field) {
		window.open(dbq[field])
	}

	return (
<>
		
	<Search label="Nr. Preventivo" placeholder="Numero preventivo" onChange={setSrcNum} value={srcNum} />
	<Search label="Cliente" onChange={setSrcCustomer} value={srcCustomer} />
	<Search label="Data" type="date-int" onChange={setSrcDate} value={srcDate} />
	{ dbq.users &&
		<Search label="Commerciale" type="select" onChange={setSrcUser} value={srcUser} options={[{ id:0, label:'-- Tutti --' }].concat(dbq.users)} />
	}
	<Search label="Stato" type="checks" onChange={setSrcStatus} value={srcStatus} options={srcStatusOptions} />

	{ dbq.lnk_csv &&
		<div className="buttons">
			<button className="button is-success" onClick={() => exportCsv('lnk_csv')}>
				<span className="icon"><i className="fas fa-file-csv"></i></span> <span>Esporta CSV</span>
			</button>
			<button className="button is-success" onClick={() => exportCsv('lnk_csvms')}>
				<span className="icon"><i className="fas fa-file-csv"></i></span> <span>Esporta CSV per Microsoft</span>
			</button>
		</div>
	}

	<Table def={tabDef} actions={actions}>{dbq.data}</Table>
	<Pager cur={pageCur} max={pageMax} set={setPageCur} />
</>
	)
}
