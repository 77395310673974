import React, { useState } from "react"
import { dspMoney } from '../lib/vconv'
import Colorpicker, { defaultColorV, defaultColorPC, colorVPrice } from '../Colorpicker'

export default function ScreenProduct(props) {
	const [ colorV, setColorV ] = useState(defaultColorV)
	const [ colorPC, setColorPC ] = useState(defaultColorPC)
	const price = colorVPrice(colorV) + parseInt(props.prod.price)

	const cartAdd = (e) => {
		props.cartAdd(props.variant.id, colorV, colorPC)
	}

	function dwlPdf() {
		if(props.prod.pdf) {
			let win = window.open(props.prod.pdf, '_blank')
			win.focus()
		}
	}

	const img = props.prod.img_big ? props.prod.img_big : 'https://preventivi.ahtitalia.it/img/default_500x500.png'

	return (
<>
	<div className="columns">
		<div className="column is-narrow is-max-half">
			<img src={img} alt="immagine prodotto" />
		</div>
		<div className="column">
			<p className="product-code">{props.prodCode}</p>
			<h1 className="product-name">{props.prod.name}</h1>
			<p>{props.prod.descr}</p>
			<Colorpicker colorV={colorV} setColorV={setColorV} colorPC={colorPC} setColorPC={setColorPC} />
			<div className="product-price">{dspMoney(price/100)}</div>
			<div className="buttons">
				<button className="button is-success" onClick={cartAdd}>
					<span className="icon"><i className="fas fa-cart-plus"></i></span><span>Aggiungi al carrello</span>
				</button>
				{ props.prod.pdf &&
					<button className="button is-secondary" onClick={dwlPdf}><span className="icon"><i className="fas fa-file-pdf"></i></span><span>Scarica PDF</span></button>
				}
			</div>
		</div>
	</div>
</>
	)
}
