export function mkPath(def, cat) {
	let idx = def.cats.findIndex((item) => item.tag===cat)
	if(idx>=0) {
		let step = [{
			key: def.cats[idx].tag,
			label: def.cats[idx].name,
			lnk: 'cat/'+def.cats[idx].tag,
		}]
		if(def.cats[idx].parent)
			return mkPath(def, def.cats[idx].parent).concat(step)
		else
			return step
	}
}

export function catSubs(def, path, pathIdx=0) {
	if(!path || pathIdx>=path.length)
		return def.cats.filter(item => !item.parent)
	else
		return def.cats.filter(item => item.parent===path[path.length-1].key)
}

export function catVars(def, cat) {
	if(!cat)
		return []
	else
		return def.vars.filter(item => item.cat===cat)
}

export function scanVar(def, cat, product) {
	return def.vars.find(item => item.cat===cat && item.product===product)
}
